html {
  width: 100%;
  height: 100%;
  margin: 0px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  margin: 0px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input, button {
  border-radius: unset!important;
}

.btn {
  padding: .375rem 1.3rem!important;
  font-size: 1em!important;
}
.has-box {
  display: flex;
  flex-direction: column;
  pointer-events: none;
}

.has-box .MuiOutlinedInput-multiline {
  border-radius: 2
}
.has-box .MuiOutlinedInput-notchedOutline {
  border-color: '#767676'
}

@media only screen and (max-width: 618px) {
  .card {
      width: 100%!important;
  }
}

th .order-4:before {
  margin-left: 3.5px;
  content: "\2191";
  opacity: 0.4;
}

th .order-4:after {
  content: "\2193";
  opacity: 0.4;
}

th .caret-4-asc:before {
  margin-left: 3.5px;
  content: "\2191";
}

th .caret-4-asc:after {
  content: "\2193";
  opacity: 0.4;
}

th .caret-4-desc:before {
  margin-left: 3.5px;
  content: "\2191";
  opacity: 0.4;
}

th .caret-4-desc:after {
  content: "\2193";
}