.toolbar {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    height: 45px;
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),
                0px 4px 5px 0px rgba(0,0,0,0.14),
                0px 1px 10px 0px rgba(0,0,0,0.12);
    z-index: 20;
}

.toolbar__navigation {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 1rem;
}

.spacer {
    flex: 1 0 0px
}

.toolbar__logo {
    overflow: hidden;
}

.toolbar__logo a {
    color: white;
    text-decoration: none;
    font-size: 1.5rem;
    white-space: nowrap;
}

.toolbar__navigation-item ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.toolbar__navigation-item li {
    padding: 0 0.5rem;
    color: white;
}

.cs-dropdown-toggle {
    padding: 0 !important;
}

.cs-dropdown-toggle:after {
    content: none;
}

.cs-dropdown-item {
    padding: 5px;
}

.cs-dropdown-item:focus {
    background-color: rgba(0,0,0,0.1);
    color: black;
}
