#root {
  background-color: #f0f1f2;
  font-size: 14px;
  width: 100%;
  height: 100%;
  margin: 0;
}
.page-heading {
  padding: 1rem;
}
.page-body {
  padding: 0 1rem;
}

.breadcrumb {
  background-color: #ffffff!important;
  margin-bottom: 0!important;
  padding: 0!important;
}
.text-white {
  color: #fff;
}
.navbar-brand{
  margin-right: 0!important;
}

a.nav-link {
  /*padding: .1rem 1rem!important;*/
  color: unset;
}

a.nav-link:hover {
  /*padding: .1rem 1rem!important;*/
  color: unset;
}

.margin-0{
  margin: 0!important;
}
.mB-0 {
  margin-bottom: 0;
}
.mB-1 {
  margin-bottom: 1rem;
}
.mB-2 {
  margin-bottom: 2rem;
}
.mR-8{
  margin-right: 8px;
}
.mT-8{
  margin-top: 8px;
}
.pT-5 {
  padding-top: 5px;
}
.pL-1 {
  padding-left: 1rem;
}
.pL-2 {
  padding-left: 2rem;
}
.padding-1{
  padding: 1rem;
}
.btn-group-left {
  color: #afb2b4!important;
  font-weight: 700!important;
  background-color: #ffffff!important;
  border-color: #afb2b4!important;
  border-radius: 8px 0 0 8px!important;
}
.btn-group-right {
  color: #afb2b4!important;
  font-weight: 700!important;
  background-color: #ffffff!important;
  border-color: #afb2b4!important;
  border-radius: 0 8px 8px 0!important;
}
.btn-group-active {
  color: #ffffff!important;
  background-color: #afb2b4!important;
}
.btn-group-left:hover,
.btn-group-right:hover {
  color: #000000!important;
  background-color: #afb2b4!important;
}
.pointer {
  cursor: pointer;
}
.disable-span {
  pointer-events: none;
  opacity: 50%;
}
.nowrap {
  white-space: nowrap;
}
.list-item {
  display: list-item;
}

.json-inspector > .json-inspector__leaf_root > .json-inspector__line > .json-inspector__key,
.json-inspector > .json-inspector__leaf_root > .json-inspector__line > .json-inspector___helper {
  display: none;
}

.react-datepicker-wrapper input {
  border-radius: 4px;
  box-shadow: inset 0 2px 2px #e9e9e9;
  border: 1px solid #aeaeae;
  line-height: 16px;
  padding: 6px 10px 5px;
}

.MultiSelect .DropDown {
  background-color: white !important;
}

.MultiSelect .Header__value {
  height: 32px !important;
  line-height: 32px !important;
}

.bg-white {
  background-color: white !important;
}


.btn-active {
  background-color: #DEE2F5 !important;
}
.page-body > div {
  border-radius: 5px;
}

.break-col {
  word-break: break-word;
  width: 15%
}

.react-datepicker-wrapper{
  display: block !important;
}

.react-datepicker__input-container{
  display: block !important;
}

.react-datepicker-wrapper input{
  width: 100%;
}

.font-20{
  font-size: 20px;
}

.react-bootstrap-table table {
  table-layout: auto !important;
}

.ck.ck-editor__editable_inline {
  min-height: 350px;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.wrapper {
  min-height: -webkit-fill-available;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.main--content {
  background-color: #f0f1f2;
}

.content--header {
  border-bottom: 1px solid #ccc;
  -webkit-box-shadow: 0 8px 6px -6px black;
  -moz-box-shadow: 0 8px 6px -6px black;
  box-shadow: 0 3px 6px -6px black;
  .header--title p {
    margin: 0;
  }
}

.body--header:after {
  content: "";
  border-bottom: 1px solid #ccc;
  display: block;
  margin-top: 2px;
  -webkit-box-shadow: 0 8px 6px -6px black;
  -moz-box-shadow: 0 8px 6px -6px black;
  box-shadow: 0 3px 6px -6px black;
  margin-left: .25rem;
}

.body--header .title {
  border-radius: .25rem .25rem 0 .25rem;
}

.table__header th {
  vertical-align: middle !important;
}

.clear-link {
  color: unset
}

.clear-link:hover {
  color: unset !important;
  text-decoration: none;
}

.custom-file-input ~ .custom-file-label::after {
  content: "ブラウズ";
}

.table thead th {
  vertical-align: middle !important;
}

.to-top-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  border-radius: 4px;
  cursor: pointer;
}

.to-top-btn:hover {
  text-decoration: none;
  color: white;
}

.img--logo {
  max-width: 100%;
  height: auto;
  max-height: 100px;
}

.faq--header {
  border-left: solid 5px;
  padding-left: 10px;
}

.number--container {
  min-width: 30px;
}

.number--style {
  min-height: 30px;
}

hr.hr-text {
  position: relative;
  border: none;
  height: 1px;
  background: #999;
}

hr.hr-text::before {
  content: attr(data-content);
  display: inline-block;
  background: #fff;
  font-weight: bold;
  font-size: 0.85rem;
  color: #999;
  border-radius: 30rem;
  padding: 0.2rem 2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.color-red {
  color: red;
}

.dropdown-heading {
  height: 36px!important;
}

.search-result em {
  color: red;
}

.skeleton-input-component {
  width: 100%;
  height: 65px;
}

.skeleton-group-button {
  width: 250px !important;
  height: 45px;
}

.search-page .btn {
  display: flex!important;
  align-items: center;
  justify-content: center;
  padding-top: 0;
  padding-bottom: 0;
}

.home-contents {
  width: calc(100% - 200px);
  height: fit-content;
}

.body-full .home-contents {
  width: 100%;
  max-width: 100%;
}

.page-item.disabled {
  pointer-events: none;
}