.side-drawer {
    max-width: 240px;
    height: 100vh;
    flex-shrink: 0;
    transition: width 0.3s ease-in-out;
}

.list__container {
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    max-width: 240px;
    padding-top: 45px;
    transition: width 0.3s ease-in-out;
    background-color: white;
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),
                0px 4px 5px 0px rgba(0,0,0,0.14),
                0px 1px 10px 0px rgba(0,0,0,0.12);
}

.side-drawer ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.side-drawer a {
    color: black;
    text-decoration: none;
}

.side-drawer li {
    padding: 1rem;
    display: flex;
    align-items: center;
    text-align: center;
}

.side-drawer a:hover {
    background-color: rgba(0,0,0,0.04);
}

.normal {
    width: 240px;
}

.mini {
    width: 60px;
}

.mobile {
    transform: translateX(-60px);
    transition: transform 0.3s ease-in-out;
}

.cs-menu-item {
    transition: font-size 0.3s ease-in-out,
                padding 0.3s ease-in-out;
}
